/* eslint-disable @next/next/no-img-element */

import { generalUtils } from '@shared/common';
import { Button } from '@shared/design-system/atoms/button';
import { Content } from '@shared/design-system/atoms/content';
import { LockKeyhole } from 'lucide-react';
import { useTranslation } from 'next-i18next';

import ReportFrameImage from '~/assets/images/plans/report_frame.png';

export const Report = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    generalUtils.scrollToElement('main');
  };

  return (
    <div className='bg-background py-12 md:py-20'>
      <Content viewClassName='items-center'>
        <h3 className='text-2xl/tight md:text-3xl/tight font-secondary text-center font-extrabold mb-1'>
          {t('plans.your_report')}
        </h3>
        <p className='font-primary text-center mb-8 md:mb-12 max-w-[36rem] mx-auto text-neutral-500'>
          {t('plans.your_report_description')}
        </p>
        <img
          src={ReportFrameImage.src}
          alt='Report Frame'
          className='w-full max-w-[36rem] mx-auto mb-8 md:mb-12 blur-sm'
        />
        <Button size='md' className='flex w-full md:max-w-[20rem] mx-auto font-primary' onClick={handleClick}>
          <LockKeyhole />
          {t('plans.unlock_my_profile')}
        </Button>
      </Content>
    </div>
  );
};
