/* eslint-disable @next/next/no-img-element */

import { Content } from '@shared/design-system/atoms/content';
import { Trans, useTranslation } from 'react-i18next';

import UnlockReportImage from '~/assets/images/email/unlock_report.png';
import UnlockReportMobileImage from '~/assets/images/email/unlock_report_mobile.png';
import { config } from '~/config';
import { useAppRouter } from '~/hooks/useAppRouter';

import { Navigation } from './_shared/navigation';
import { EmailForm } from './EmailForm';

export const EmailPage = () => {
  const { t } = useTranslation();
  const { pushNextFunnelRoute } = useAppRouter('/loader');

  const handleSubmit = () => {
    pushNextFunnelRoute();
  };

  return (
    <div className='flex flex-col min-h-screen bg-background'>
      <Navigation />
      <Content
        gridClassName='flex-1 items-start'
        viewClassName='flex flex-col-reverse md:flex-row md:items-end gap-8 mt-6 md:mt-[10%]'
      >
        <div className='flex-1'>
          <h1 className='font-secondary text-2xl/tight md:text-3xl/none font-extrabold mb-2'>
            {t('email:headlines.where_should_we_send_your_plan')}
          </h1>
          <p className='text-sm md:text-md mb-4'>
            <Trans
              t={t}
              i18nKey='email:enter_email_to_unlock_summary'
              values={{ projectName: config.name }}
              components={{ boldBlack: <b className='text-black'></b> }}
            />
          </p>
          <EmailForm buttonCopy={t('continue')} onSubmit={handleSubmit} preventDefault />
        </div>
        <div className='flex-1'>
          <img src={UnlockReportImage.src} alt='Unlock Report' className='w-full hidden md:block' />
          <img src={UnlockReportMobileImage.src} alt='Unlock Report' className='w-[84%] mx-auto block md:hidden' />
        </div>
      </Content>
    </div>
  );
};
