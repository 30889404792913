import { Content } from '@shared/design-system/atoms/content';
import { cn } from '@shared/design-system/utils/shadcn';
import { Loader2 } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';

import { useAppRouter } from '~/hooks/useAppRouter';

const ANIMATION_DURATION = 20;

const LoaderPage = () => {
  const { funnelName, pushNextFunnelRoute } = useAppRouter('/plans');
  const { t } = useTranslation(funnelName);
  const loaderSteps =
    t<'loader.steps', { returnObjects: true }, { title: string; description: string }[]>('loader.steps', {
      returnObjects: true,
    }) || [];
  const [state, setState] = useState(0);
  const callbackRef = useRef<() => void>();

  useEffect(() => {
    callbackRef.current = pushNextFunnelRoute;
  }, [callbackRef, pushNextFunnelRoute]);

  useEffect(() => {
    let value = 0;

    const interval = setInterval(() => {
      if (value < ANIMATION_DURATION) {
        value += 1;

        if (value % 5 === 0) {
          setState(value / 5);
        }
      }

      if (value >= ANIMATION_DURATION) {
        callbackRef.current?.();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Content gridClassName='bg-background' viewClassName='flex flex-col items-center min-h-screen mt-8 md:mt-20'>
      <div className='max-w-[32rem] mx-auto'>
        <h1 className='text-2xl/tight md:text-3xl/tight font-secondary font-extrabold text-center mb-8 md:mb-14'>
          {t('loader:calculating_results_and_creating_your_personalized_report')}
        </h1>
        <div className='flex flex-col gap-6 max-w-[28rem] mx-auto'>
          {loaderSteps.map((item, index) => (
            <div key={index}>
              <div className='flex justify-between items-start mb-2'>
                <div>
                  <p className='font-semibold'>{item.title}</p>
                  <p className='text-sm text-neutral-400'>{item.description}</p>
                </div>
                {state === index ? (
                  <div className='animate-spin'>
                    <Loader2 size={18} />
                  </div>
                ) : null}
              </div>
              <div className='bg-white border border-neutral-300 rounded-full overflow-hidden'>
                <div
                  className={cn(
                    'h-[10px] bg-green-600 w-0',
                    state === index ? 'animate-loader-progress' : '',
                    state > index ? 'w-full' : '',
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </Content>
  );
};

export default LoaderPage;
