import { PlasmicComponent, extractPlasmicQueryData, PlasmicRootProvider } from '@plasmicapp/loader-nextjs';
import type { GetStaticPaths, GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { CatchAllPage } from '~/layouts/catch-all-page';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import i18nextConfig from '../next-i18next.config';
import { PLASMIC } from '../plasmic-init';

export default CatchAllPage;

export const getStaticProps: GetStaticProps = async (context) => {
  const { catchall } = context.params ?? {};
  const locale = context.locale || 'en-US';
  const plasmicPath =
    typeof catchall === 'string' ? catchall : Array.isArray(catchall) ? `/${catchall.join('/')}` : '/';

  const plasmicData = await PLASMIC.maybeFetchComponentData(plasmicPath);
  const translations = await serverSideTranslations(
    locale,
    [
      'common',
      'landing',
      'email',
      'summary',
      'plans',
      'checkout',
      'upsells',
      'upsellMeditation',
      'upsellBundle',
      'success',
      'articles',
      'liver',
      'email',
      'loader',
      'cortisol-report',
    ],
    i18nextConfig,
  );

  if (!plasmicData) {
    // non-Plasmic catch-all
    return {
      props: {
        path: plasmicPath,
        ...translations,
      },
    };
  }
  const pageMeta = plasmicData.entryCompMetas[0];
  // Cache the necessary data fetched for the page
  const queryCache = await extractPlasmicQueryData(
    <PlasmicRootProvider loader={PLASMIC} prefetchedData={plasmicData} pageParams={pageMeta.params}>
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>,
  );

  return {
    props: {
      path: plasmicPath,
      locale,
      plasmicData,
      queryCache,
      ...translations,
    },
    revalidate: process.env.NODE_ENV === 'development' ? 10 : undefined,
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const pageModules = await PLASMIC.fetchPages();
  const filteredPages = pageModules.filter(({ path }) => !/^\/o.*$/.test(path));

  return {
    paths: filteredPages.map((mod) => ({
      params: {
        catchall: mod.path.substring(1).split('/'),
      },
    })),
    fallback: 'blocking',
  };
};
