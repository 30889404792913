/* eslint-disable @next/next/no-img-element */

import { Content } from '@shared/design-system/atoms/content';
import { StaticImageData } from 'next/image';
import { Trans, useTranslation } from 'next-i18next';

import CortisolReportHeroImage from '~/assets/images/landing/cortisol-report-hero.png';
import LandingHeroImage from '~/assets/images/landing/hero.png';
import { GenderSelection } from '~/components/GenderSelection';
import { Funnel } from '~/constants';
import { useAppRouter } from '~/hooks/useAppRouter';

import { Footer } from '../_shared/footer';
import { Navigation } from '../_shared/navigation';

const FUNNEL_TO_IMAGE_MAPPING: Partial<Record<Funnel, StaticImageData>> = {
  'cortisol-report': CortisolReportHeroImage,
};

export const ReportLanding = () => {
  const { funnelName } = useAppRouter();
  const { t } = useTranslation(funnelName);
  const imageSrc =
    funnelName && FUNNEL_TO_IMAGE_MAPPING[funnelName] ? FUNNEL_TO_IMAGE_MAPPING[funnelName]?.src : LandingHeroImage.src;

  return (
    <>
      <div className='flex flex-col min-h-screen bg-green-100'>
        <Navigation />
        <Content gridClassName='flex-1' viewClassName='flex flex-col justify-start md:justify-center py-4'>
          <div className='flex flex-col md:flex-row-reverse items-center md:gap-8'>
            <img src={imageSrc} alt='Landing Hero' className='w-full md:w-1/2 h-auto mb-2 max-w-[85%] mx-auto' />
            <div className='w-full md:w-1/2 text-center md:text-left'>
              <h1 className='text-3xl/none md:text-4xl/none font-secondary font-extrabold mb-4'>
                {t('landing.title')}
              </h1>
              <p className='mb-8 font-primary'>
                <Trans t={t} i18nKey='landing.description' components={{ b: <b /> }} />
              </p>
              <p className='text-xl font-medium mb-4 font-primary'>{t('common:start_selecting_gender')}</p>
              <GenderSelection btnFemaleCopy='Female' btnMaleCopy='Male' isReversed={true} />
            </div>
          </div>
        </Content>
      </div>
      <Footer />
    </>
  );
};
