import { Content } from '@shared/design-system/atoms/content';
import { cn } from '@shared/design-system/utils/shadcn';
import { Check } from 'lucide-react';
import { useTranslation, Trans } from 'next-i18next';

export const Intro = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  const { t } = useTranslation();
  const includedItems = t<'plans.includes', { returnObjects: true }, string[]>('plans.includes', {
    returnObjects: true,
  });

  return (
    <Content gridClassName='mt-6 mb-10 md:my-16'>
      <h1 className='text-center text-3xl/tight md:text-[3rem]/tight font-secondary font-extrabold mb-8 md:mb-12'>
        {t('plans:title')}
      </h1>
      <div className={cn('flex flex-col md:flex-row gap-10 max-w-[64rem] mx-auto', className)} id='main'>
        {children}
        <div className='flex-1'>
          <h2 className='text-2xl font-primary font-semibold mb-4'>{t('plans:whats_included')}</h2>
          <div className='flex flex-col gap-4'>
            {includedItems.map((item) => (
              <div className='flex items-center gap-4' key={item}>
                <Check />
                <p className='font-primary'>
                  <Trans t={t} i18nKey={item} components={{ b: <b /> }} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Content>
  );
};
