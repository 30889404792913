import { ReactNode } from 'react';

import { cn } from '../utils/shadcn';

export const View = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div className={cn('w-full justify-self-center max-w-max-content', 'col-[content-start_/_content-end]', className)}>
      {children}
    </div>
  );
};
