/* eslint-disable @next/next/no-img-element */

import { Content } from '@shared/design-system/atoms/content';
import { useTranslation } from 'next-i18next';

import PublicationsImage from '~/assets/images/plans/publications.png';

export const Publications = () => {
  const { t } = useTranslation();

  return (
    <Content gridClassName='mb-[4rem] md:mb-[6.25rem]'>
      <h3 className='md:text-2xl/tight font-secondary mb-4 md:mb-8 text-center font-bold'>
        {t('plans:techniques_used_in_results_report_covered_in')}
      </h3>
      <img className='w-full max-w-[44rem] mx-auto' src={PublicationsImage.src} alt='Publications' />
    </Content>
  );
};
