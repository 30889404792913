import { generalUtils } from '@shared/common';
import { Button } from '@shared/design-system/atoms/button';
import { Content } from '@shared/design-system/atoms/content';
import { LockKeyhole } from 'lucide-react';
import { useTranslation } from 'next-i18next';

import { TestimonialsList } from '~/components';

export const Reviews = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    generalUtils.scrollToElement('main');
  };

  return (
    <div className='bg-background-primary py-12 md:py-20'>
      <Content>
        <h3 className='text-2xl/tight md:text-[3rem]/tight text-center font-secondary font-extrabold mb-6 md:mb-8'>
          {t('plans:success_stories')}
        </h3>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-10 mb-10 max-w-[84rem] mx-auto'>
          <TestimonialsList />
        </div>
        <Button size='md' className='flex w-full md:max-w-[20rem] mx-auto font-primary' onClick={handleClick}>
          <LockKeyhole />
          {t('plans:access_my_results_report')}
        </Button>
      </Content>
    </div>
  );
};
