import { PlasmicComponent, ComponentRenderData, PlasmicRootProvider } from '@plasmicapp/loader-nextjs';
import Error from 'next/error';

import { useAppRouter } from '~/hooks/useAppRouter';

import { PLASMIC } from '../plasmic-init';

import { EmailPage } from './email-page';
import { ReportLanding } from './landings/report-landing';
import LoaderPage from './loader-page';
import { CheckoutPage } from './plans/checkout-page';
import { PlansPage } from './plans/plans-page';

const PATH_TO_PAGE_MAP: Partial<Record<string, () => JSX.Element>> = {
  '/liver': ReportLanding,
  '/liver/email': EmailPage,
  '/liver/loader': LoaderPage,
  '/liver/plans': PlansPage,
  '/liver/checkout': CheckoutPage,
  // cortisol report
  '/cortisol-report': ReportLanding,
  '/cortisol-report/email': EmailPage,
  '/cortisol-report/loader': LoaderPage,
  '/cortisol-report/plans': PlansPage,
  '/cortisol-report/checkout': CheckoutPage,
};

export const CatchAllPage = ({
  plasmicData,
  queryCache,
  path,
}: {
  plasmicData?: ComponentRenderData;
  queryCache?: Record<string, unknown>;
  path: string;
}) => {
  const router = useAppRouter();
  const NativePage = PATH_TO_PAGE_MAP[path];

  if (NativePage) {
    return <NativePage />;
  } else if (!plasmicData || plasmicData.entryCompMetas.length === 0) {
    return <Error statusCode={404} />;
  }

  const pageMeta = plasmicData.entryCompMetas[0];

  return (
    <PlasmicRootProvider
      loader={PLASMIC}
      prefetchedData={plasmicData}
      prefetchedQueryData={queryCache}
      pageParams={pageMeta.params}
      pageQuery={router.query}
      globalVariants={[]}
    >
      <PlasmicComponent component={pageMeta.displayName} />
    </PlasmicRootProvider>
  );
};
