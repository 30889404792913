import { Content } from '@shared/design-system/atoms/content';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { config } from '~/config';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Content viewClassName='py-6 md:text-center'>
      <p className='text-sm mb-2'>
        {t('disclaimers.copyright', { currentYear: new Date().getFullYear(), projectName: config.name })}
      </p>
      <p className='text-xs mb-2'>{t('disclaimers.healthcare', { projectName: config.name })}</p>
      <div className='flex gap-4 md:justify-center'>
        <Link className='text-sm underline' href='/terms-of-services'>
          {t('terms_of_services')}
        </Link>
        <Link className='text-sm underline' href='/reviews'>
          {t('reviews')}
        </Link>
        <Link className='text-sm underline' target='_blank' href={config.webAppUrl}>
          Access my plan
        </Link>
      </div>
    </Content>
  );
};
