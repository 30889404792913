import { Content } from '@shared/design-system/atoms/content';
import { Check } from 'lucide-react';
import { Trans, useTranslation } from 'next-i18next';

export const ReportCovers = () => {
  const { t } = useTranslation();
  const expectations = t<'plans.expectations', { returnObjects: true }, string[]>('plans.expectations', {
    returnObjects: true,
  });

  return (
    <Content gridClassName='my-12 md:my-20'>
      <div className='flex flex-col md:flex-row gap-6 md:gap-12'>
        <h3 className='text-2xl md:text-4xl/tight font-secondary font-semibold mb-2 text-center md:text-left'>
          {t('plans.what_to_expect')}
        </h3>
        <div className='flex flex-col gap-4'>
          {expectations.map((expectation, index) => (
            <div className='flex gap-4 items-center' key={index}>
              <Check />
              <p className='flex-1 font-primary'>
                <Trans t={t} i18nKey={expectation} components={{ b: <b /> }} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </Content>
  );
};
