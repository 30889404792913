import { generalUtils } from '@shared/common';
import { Button } from '@shared/design-system/atoms/button';
import { Content } from '@shared/design-system/atoms/content';
import { Check, LockKeyhole } from 'lucide-react';
import { Trans, useTranslation } from 'next-i18next';

export const Benefits = () => {
  const { t } = useTranslation();
  const benefits = t<'plans.benefits', { returnObjects: true }, string[]>('plans.benefits', {
    returnObjects: true,
  });
  const learnings = t<'plans.learnings', { returnObjects: true }, string[]>('plans.learnings', {
    returnObjects: true,
  });

  const handleClick = () => {
    generalUtils.scrollToElement('main');
  };

  return (
    <Content gridClassName='py-12 md:py-20'>
      <div className='flex flex-col md:flex-row gap-10 md:gap-20 mb-12'>
        <div>
          <h3 className='text-2xl font-primary font-semibold mb-4'>{t('plans:how_youll_benefit')}</h3>
          <div className='flex flex-col gap-4'>
            {benefits.map((benefit, index) => (
              <div key={index} className='flex gap-4 items-center'>
                <Check size={24} />
                <p className='flex-1 font-primary'>
                  <Trans i18nKey={benefit} components={{ b: <b /> }} />
                </p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h3 className='text-2xl font-primary font-semibold mb-4'>{t('plans:key_learnings')}</h3>
          <div className='flex flex-col gap-4'>
            {learnings.map((learning, index) => (
              <div key={index} className='flex gap-4 items-center'>
                <Check />
                <p className='flex-1 font-primary'>
                  <Trans i18nKey={learning} components={{ b: <b /> }} />
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button size='md' className='flex w-full md:max-w-[20rem] mx-auto font-primary' onClick={handleClick}>
        <LockKeyhole />
        {t('plans:access_my_results_report')}
      </Button>
    </Content>
  );
};
