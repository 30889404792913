import { Content } from '@shared/design-system/atoms/content';
import { cn } from '@shared/design-system/utils/shadcn';
import { ReactNode } from 'react';

import LogoSVG from '~/assets/icons/logo.svg';

const LOGO_SIZE_MAP = {
  md: 'h-[30px] md:h-[34px]',
  sm: 'h-[24px] md:h-[px]',
};

export const Navigation = ({
  children,
  className,
  gridClassName,
  viewClassName,
  logoClassName,
  logoSize = 'md',
}: {
  className?: string;
  gridClassName?: string;
  viewClassName?: string;
  logoClassName?: string;
  children?: ReactNode | [ReactNode, ReactNode];
  logoSize?: keyof typeof LOGO_SIZE_MAP;
}) => {
  const leftChildren = Array.isArray(children) ? children[0] : null;
  const rightChildren = Array.isArray(children) ? children[1] : children;

  return (
    <div className={cn('py-3 md:py-4 bg-white', className)}>
      <Content gridClassName={gridClassName} viewClassName={cn('flex justify-center items-center', viewClassName)}>
        {leftChildren}
        <LogoSVG className={cn(LOGO_SIZE_MAP[logoSize], logoClassName)} />
        {rightChildren}
      </Content>
    </div>
  );
};
