/* eslint-disable @next/next/no-img-element */

import { generalUtils } from '@shared/common';
import { Button } from '@shared/design-system/atoms/button';
import { Content } from '@shared/design-system/atoms/content';
import { LockKeyhole } from 'lucide-react';
import { useTranslation } from 'next-i18next';

import ResultsFrameImage from '~/assets/images/plans/results_frame.png';

export const Results = () => {
  const { t } = useTranslation();

  const handleClick = () => {
    generalUtils.scrollToElement('main');
  };

  return (
    <Content gridClassName='mt-16 mb-12 md:my-20'>
      <h3 className='text-2xl/tight md:text-3xl/tight font-secondary text-center font-extrabold mb-6 md:mb-8'>
        {t('plans.your_results')}
      </h3>
      <img src={ResultsFrameImage.src} alt='Results Frame' className='w-full max-w-[26rem] mx-auto mb-8 md:mb-12' />
      <Button size='md' className='flex w-full md:max-w-[24rem] mx-auto font-primary' onClick={handleClick}>
        <LockKeyhole />
        {t('plans:unlock_my_results')}
      </Button>
    </Content>
  );
};
