import { Grid } from './grid';
import { View } from './view';

type ContentProps = {
  children: React.ReactNode;
  gridClassName?: string;
  viewClassName?: string;
};

export const Content = ({ children, gridClassName, viewClassName }: ContentProps) => {
  return (
    <Grid as='section' className={gridClassName}>
      <View className={viewClassName}>{children}</View>
    </Grid>
  );
};
