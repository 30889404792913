import { useTranslation } from 'next-i18next';

import { useAppRouter } from '~/hooks/useAppRouter';

import { Navigation } from '../_shared/navigation';
import { TimeBanner } from '../TimeBanner';

import { Intro } from './_components/1-intro';
import { Publications } from './_components/2-publications';
import { Results } from './_components/3-results';
import { Report } from './_components/4-report';
import { ReportCovers } from './_components/5-covers';
import { Reviews } from './_components/6-reviews';
import { Benefits } from './_components/7-benefits';
import { PlanSelectionReport } from './_components/plan-selection-report';

export const PlansPage = () => {
  const { funnelName } = useAppRouter();
  const { t, i18n } = useTranslation();
  i18n.setDefaultNamespace(funnelName);

  return (
    <>
      <Navigation
        className='border-b border-neutral-200'
        viewClassName='w-full md:w-full md:justify-between flex-row gap-4'
        logoSize='sm'
        logoClassName='hidden md:block'
      >
        {[
          null,
          <div key='timer' className='flex items-center gap-2 md:gap-3'>
            <p className='text-xs md:text-sm text-neutral-500'>{t('plans:results_saved_for')}</p>
            <TimeBanner className='justify-center !text-lg font-extrabold' />
          </div>,
        ]}
      </Navigation>
      <Intro>
        <div className='flex flex-1 flex-col gap-3'>
          <PlanSelectionReport />
        </div>
      </Intro>
      <Publications />
      <Results />
      <Report />
      <ReportCovers />
      <Reviews />
      <div className='flex items-center gap-2 justify-center py-4 bg-background'>
        <p className='text-sm'>{t('plans:your_special_offer_expires_soon')}</p>
        <TimeBanner className='justify-center !text-lg font-extrabold' />
      </div>
      <Benefits />
    </>
  );
};
